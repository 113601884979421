var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function ($event) {
            return _vm.fetch()
          },
        },
      }),
      !_vm.api.isLoading && _vm.dataCompanyMatched != null
        ? _c("v-data-table", {
            staticClass: "elevation-1 mt-4",
            attrs: {
              items: _vm.dataCompanyMatched,
              headers: _vm.dataHeader,
              search: _vm.searchCompanyMatched,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.name != null
                        ? _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.vision_key",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.vision_key != null
                        ? _c("span", [
                            _vm._v(" " + _vm._s(item.vision_key) + " "),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.excel_key",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.excel_key != null
                        ? _c("span", [
                            _vm._v(" " + _vm._s(item.excel_key) + " "),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.industry",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.industry != null
                        ? _c("span", [
                            _vm._v(" " + _vm._s(item.industry) + " "),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.business_nature",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.business_nature != null
                        ? _c("span", [
                            _vm._v(" " + _vm._s(item.business_nature) + " "),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "py-3",
                          attrs: { width: "auto", height: "auto", flat: "" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4 my-3",
                                        },
                                        [_vm._v(" Company Empty Industry ")]
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-3",
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fetch()
                                            },
                                          },
                                        },
                                        [_vm._v(" Refresh ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateCompanyIndustry()
                                            },
                                          },
                                        },
                                        [_vm._v(" Fix ")]
                                      ),
                                      _c(
                                        "JsonCSV",
                                        {
                                          staticClass: "mx-2",
                                          attrs: {
                                            data: _vm.dataCompanyMatched,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { color: "primary" } },
                                            [_vm._v(" Download ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          label: "Search",
                                        },
                                        model: {
                                          value: _vm.searchCompanyMatched,
                                          callback: function ($$v) {
                                            _vm.searchCompanyMatched = $$v
                                          },
                                          expression: "searchCompanyMatched",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.Index",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dataCompanyMatched.indexOf(item) + 1) +
                          " "
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2634887909
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }